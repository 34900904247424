.hero {
  min-height: 40vh;
  clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%);

  .hero-content {
    margin-top: -100px;

    h1 {
      font-size: 2.4rem;
      font-weight: 500;
    }

    h2,
    p {
      @apply py-2;

      font-size: 1.6rem;
    }

    a {
      @apply inline-block py-4 px-8 mt-6 bg-neon-blue font-medium uppercase text-primary rounded-full;

      font-size: 1.2rem;
      transition: $bez;

      &:hover {
        @apply bg-pink;
        transition: $bez;

      }
    }

    @include MQ(S) {
      h1,
      h2 {
        font-size: 1.8rem;
      }

      p {
        font-size: 1.2rem;
      }

      a {
        font-size: 1rem;
      }
    }
  }

  #circle {
    position: relative;
    width: 300px;

    text {
      font-size: 1.15rem;
      color: color(neon-blue);
    }

    svg {
      position: absolute;
      right: -50px;
      bottom: -100px;
      width: 300px;
      height: 300px;
      animation-name: rotate;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    @include MQ(S) {
      display: none;
    }
  }
}

@keyframes rotate {
    from { transform: rotate(360deg); }
    to { transform: rotate(0); }
}

.bg-hero {
  @apply bg-primary text-neon-blue;
}
