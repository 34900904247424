.logo {
  width: 200px;
  height: auto;

  @include MQ(S) {
    width: 150px;
  }
}

.menu-icon {
  width: 75px;
  height: 75px;
  transition: $bez;

  &:hover {
    background-color: color(primary);
    color: color(neon-blue);
    transition: $bez;
  }
}
