body {
  font-family: "urw-din", sans-serif;
  font-size: 100%;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 1.2rem;
}

h6 {
  font-size: 0.8rem;
}

h1,
h2 {
  font-weight: 600;
}

h3,
h4,
h5 {
  font-weight: 500;

  strong {
    font-weight: 600;
  }
}

p {
  font-weight: 300;
}

strong {
  font-weight: 600;
}
