.cta {
  transition: $bez;

  p,
  h2,
  h3,
  h4,
  h5 {
    @apply text-4xl font-medium;
  }
}
