.full-image-text {
  .text {
    h2,
    h3,
    h4,
    h5,
    p {
      @apply mb-6;
    }
  }
}

.project-image {
  animation: kenBurns 30s ease;
  animation-iteration-count: 1;
}

.add-overlay {
  position: relative;

  &::after {
    @apply bg-primary absolute top-0 left-0 w-full h-full;

    content: "";
    z-index: 5;
    opacity: 0.7;
  }
}
