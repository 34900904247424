@function color($key) {
    @if map-has-key($colors, $key) {
      @return map-get($colors, $key);
    }

    @warn "Unknown `#{$key}` in $theme-colors.";

    @return null;
}

$colors:(
    primary: #060091,
    neon-blue: #80e6ff,
    light-blue: #e0f7ff,
    font: black,
    light-grey: #f4f5f6,
    soft-grey: #efefef,
    slate: #3a3943,
);

$accordionmenu-arrows: True;
$accordionmenu-arrow-color: black;

$bezier: cubic-bezier(0.645, 0.045, 0.355, 1);

$bez: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
