.text-block {
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  li,
  span,
  p,
  form,
  .accreditations {
    width: 70%;

    @include MQ(S) {
      width: 100%;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-6;
  }

  h3,
  h4,
  h5,
  h6 {
    @apply mt-12;
  }

  h6 {
    @apply text-mid-grey border-l-4 pl-4 border-mid-grey uppercase text-base;

    font-weight: 500;
  }

  .container,
  .mx-auto {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  p {
    @apply mb-6;
  }

  ul {
    @apply list-disc my-6 pl-6;
  }

  .accreditations {
    width: 70%;
  }

  @include MQ(S) {
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      max-width: 100%;
    }
  }
}

.quote-block {
  .quote > p {
    @apply text-xl;

    line-height: 2rem;
  }
}

.blog-entry {
  .text-block {
    h2 {
      margin-bottom: 1rem !important;
    }
  }
}
