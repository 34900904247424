.glide__bullets {
  position: relative;
  bottom: unset;

  .glide__bullet {
    background-color: white;
    border: none;
    box-shadow: none;
  }

  .glide__bullet--active {
    background-color: #000;
  }
}
