html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;

  overscroll-behavior-y: none;
}

.slider-underlay {
  @apply fixed h-screen w-screen top-0 bottom-0 bg-light-blue;

  animation-delay: 0.8s;
  opacity: 0;
  visibility: hidden;
  transition: $bez;
}

.show-underlay {
  visibility: visible;
  opacity: 1;
  transition: $bez;
}

.case-wrapper {
  @include MQ(M) {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.app {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  background-color: var(--color-grey);
}

.is-device main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // overflow-y: scroll;
  // -webkit-overflow-scrolling: touch;
}
