.flow {
  .flow-item {
    h3,
    h4,
    h5 {
      @apply text-lg pl-6;

      border-left: 1px solid color(neon-blue);
    }

    h3,
    h4,
    h5,
    p {
      @apply mb-6;
    }
  }
}
