* {
	box-sizing: border-box;
}

html, body {
	min-height: 100%;
	background: #fff;
	overscroll-behavior-y: none;
}

body {
	position: static;
	overflow-y: auto;
	font-size: 18px;
	text-rendering: optimizeLegibility;
	overflow-x: hidden !important;
	max-width: 100vw;
	transition: $bez;
}

.site-wrapper {
	position: relative;
	overflow-x: hidden;
	max-width: 100vw;
}

.slider-open {
	position: fixed;
	overflow-y: scroll;
	overflow-x: hidden;
}

.container {
	@include MQ(S) {
		@apply px-4;
	}
}

.no-bg,
.no-bg:hover {
	background: none !important;
}

.bg-blue-gradient {
	background: rgb(6,0,145);
	background: linear-gradient(180deg, rgba(6,0,145,1) 31%, rgba(13,40,224,1) 100%);
}

.h-nearly-screen {
	height: calc(100vh - 225px);
}

.w-70 {
	width: 70%;

	@include MQ(S) {
		width: 100%;
	}
}

.grecaptcha-badge {
	z-index: 99;
}

input#ImagHoneyPot {
	display: none;
}
