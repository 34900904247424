.video-block {
  .hero-video {
    height: 810px;
  }
}

.fw-video {
  min-height: 1080px;

  @include MQ(XL) {
    min-height: 810px;
  }

  @include MQ(L) {
    min-height: 430px;
  }

  @include MQ(M) {
    min-height: 400px;
  }

  @include MQ(S) {
    min-height: 0;
    height: 235px;
  }
}
