.slider {
  //--slidesTotal: 8;

  // position: fixed;
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9;
  overscroll-behavior: contain;

  height: calc(var(--vh, 1vh) * 100);

  visibility: hidden;
  opacity: 0;

  will-change: transform;

  &__button-close {
    position: fixed;
    top: -11.11111vw;
    left: calc(100vw - 43.056vw);
    z-index: 1001;

    // position: fixed;
    // top: var(--spacing-15);
    // right: var(--spacing-15);
    // z-index: 1001;

    width: 56.944vw;
    height: 56.944vw;

    will-change: transform;
  }

  &__button-close-icon {
    width: 56.944vw;
    height: 56.944vw;

    overflow: visible;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(var(--slidesTotal), var(--col-9));
    grid-column-gap: 4.167vw;
    align-items: center;

    padding: 0 4.167vw;

    height: calc(var(--vh, 1vh) * 100);
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none;

    will-change: transform;
  }

  &__item-img-wrap {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    overflow: hidden;

  }

  .image-overlay {
    @apply absolute w-full h-full top-0 left-0 bg-primary;
    content: "";
    opacity: 0;
    z-index: 1;

    animation-delay: 0.6s;
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
    z-index: 2;

    user-select: none;
  }

  &__item-heading-wrap,
  &__item-button-wrap {
    display: flex;

    overflow: hidden;
    width: 100%;
  }

  &__item-button-wrap {
    pointer-events: all;
  }

  &__item-heading {
    pointer-events: none;

    font-size: 2rem;
    line-height: 3rem;
    color: var(--color-white);
    text-transform: uppercase;
    text-align: center;

    transform-origin: center bottom;
    will-change: transform;
  }

  &__item-button {
    margin: 4.167vw auto 0;

    color: var(--color-white);

    &:hover {
      background: none !important;
      color: var(--color-white) !important;
    }

    @include MQ(S) {
      background: none !important;
      appearance: none;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: center;

    position: fixed;

    width: 100%;
    height: 100vh;

    //overflow-x: unset;

    &__button-close {
      position: fixed;
      top: 1.042vw;
      left: calc(100vw - 14.23611vw - 2.083vw);
      right: unset;

      @include MQ(S) {
        left: unset;
        right: -100px;
      }
    }

    &__button-close,
    &__button-close-icon {
      width: 14.236vw;
      height: 14.236vw;
    }

    &__button-close-icon {
      position: absolute;
      top: 0;
      left: 0;
    }

    &__container {
      grid-template-columns: repeat(var(--slidesTotal), var(--col-4));
      grid-column-gap: 4.167vw;

      padding: 0 var(--col-1);

      height: unset;

      cursor: grab;
    }

    &__item-heading {
      font-size: 3rem;
      line-height: 4rem;
    }

    &__item-button {
      margin: 1.389vw auto;
    }

    &__item-img {
      max-width: unset;
      // width: calc(100% + var(--col-3)) !important;

      pointer-events: none;
      user-select: none;

      transform-origin: left center;
      transform: scale(1.75);
      will-change: transform;
    }

    &__progress-wrap {
      display: block;

      position: absolute;
      left: var(--col-2);
      bottom: 3.125vw;

      width: var(--col-8);
      height: 1px;

      overflow: hidden;

      background-color: #d6d6d6;

      transform-origin: left center;
      will-change: transform;
    }

    &__progress {
      display: block;

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 1px;

      background-color: var(--color-black);

      transform: scaleX(0);
      transform-origin: left center;
      will-change: transform;
    }
  }
}

.is-dragging .slider__container {
  cursor: grabbing;
}

.is-device .slider {
  position: fixed;
  //position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;

  height: calc(var(--vh, 1vh) * 100);

  overflow-x: scroll;
}

.is-device .slider__container {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
}

.is-device .slider .nav__button-close {
  position: fixed !important;
  left: 0;
}

.is-device .slider .slider__progress-wrap {
  display: none;
}
