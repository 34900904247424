// breakpoints

$XS:    350px;
$S:     520px;
$M:     768px;
$L:     1025px;
$XL:    1440px;
$XXL:   2560px;

// media queries

@mixin MQ($canvas) {
  @if $canvas == XS {
    @media only screen and (max-width: $XS) { @content; }
  }

  @else if $canvas == S {
    @media only screen and (max-width: $S) { @content; }
  }

  @else if $canvas == M {
    @media only screen and (max-width: $M) { @content; }
  }

  @else if $canvas == L {
    @media only screen and (max-width: $L) { @content; }
  }

  @else if $canvas == XL {
    @media only screen and (max-width: $XL) { @content; }
  }

  @else if $canvas == XXL {
    @media only screen and (max-width: $XXL) { @content; }
  }
}

// Calculate Rem Functions
@function calculaterem($size) {
  $remSize: $size / 16px;

  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculaterem($size);
}

@keyframes fadeUp {
  0% {
    position: relative;
    opacity: 0;
    bottom: -25px;
  }

  100% {
    position: relative;
    opacity: 1;
    bottom: 0;
  }
}

@mixin fadeUp($time) {
  animation: fadeUp $time $bezier 1 both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

@keyframes fadeInBG {
  0% {
    opacity: 0;
    height: 0%;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}

@mixin fadeInBG($time) {
  animation: fadeInBG $time $bezier 1 both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.fadeInBG {
  @include fadeInBG(1s);
}

@keyframes colourSwitch {
  0% {
    background: color(primary);
    color: color(neon-blue);
  }

  50% {
    background: color(neon-blue);
    color: color(primary);
  }

  100% {
    background: color(primary);
    color: color(neon-blue);
  }
}

@mixin colourSwitch($time) {
  animation: colourSwitch $time $bezier infinite both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

@keyframes kenBurns {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}
