.nav-screen {
  opacity: 0;
  visibility: hidden;
  transition: $bez;
}

.nav-show {
  visibility: visible;
  opacity: 1;
  transition: $bez;
}

.navigation {
  list-style: none;
  padding: 0;
  font-size: 3rem;

  li > a {
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: color(neon-blue);
    color: transparent;
    text-transform: uppercase;
    @include fadeUp(0.4s);
    transition: $bez;

    &:hover {
      background: none;
      color: color(neon-blue);
      font-style: italic;
    }

    &:nth-child(1) {
      animation-delay: 0.8s;
    }

    &:nth-child(2) {
      animation-delay: 1.2s;
    }

    &:nth-child(3) {
      animation-delay: 1.6s;
    }
  }
}
