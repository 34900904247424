.blog-entry {
  .text-block {
    display: block;

    .container {
      display: block;
      justify-content: unset;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  span,
  p,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply w-full;
  }

  span,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply w-auto inline-block;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply my-4;
  }

  span {
    @apply my-2;
  }

  ul,
  li {
    @apply w-full;
  }

  a {
    @apply h-auto;
  }

  a > span {
    @apply inline my-0 w-auto mx-1 underline;
  }

  img,
  ul {
    @apply block my-6 w-full;
  }

  .bio-image {
    width: auto !important;
  }

  .social {
    display: flex;

    li {
      @apply w-10;
      display: flex;
    }
  }

  .entry-header {
    h1 {
      font-weight: 600;
    }

    img {
      margin: 0 !important;
    }

    i {
      font-size: 1.4rem;
    }
  }
}

.article-link {
  &:hover {
    &::after {
      opacity: 0.3;
      transition: $bez;
    }
  }

  a:hover {
    @apply text-white;
  }
}

.avatar-p {
  width: auto;
}
